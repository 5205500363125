export default [
  {
    header: 'Dịch vụ',
  },
  {
    title: 'Quản lý đơn hàng',
    route: 'order-management',
    icon: 'MailIcon',
  },
  {
    title: 'Ngân hàng rút tiền',
    route: 'bank-list',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Quản lý rút tiền',
    route: 'wallet',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Lịch sử giao dịch',
    route: 'transaction-history',
    icon: 'CalendarIcon',
  },
  {
    header: 'Kênh USDT',
  },
  {
    title: 'Quản lý USDT',
    route: 'usdt-management',
    icon: 'ColumnsIcon',
  },
  {
    title: 'Lịch sử rút tiền',
    route: 'usdt-withdraw-history',
    icon: 'CheckSquareIcon',
  },
  {
    header: 'Tài khoản',
  },
  {
    title: 'Quản lý tài khoản',
    route: 'account-manager',
    icon: 'CalendarIcon',
  },
]
